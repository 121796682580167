<template>
  <div class="contact-cont">
    <van-collapse v-model="activeNames">
      <van-collapse-item title="1.如何申请服务？" name="1">
        您可以通过“大诚保”微信公众号在线提交资料并申请服务也可以拨打：4008 336 886客服热线。
      </van-collapse-item>
      <van-collapse-item title="2.查询不到订单？" name="2">
        您可以通过“大诚保”微信公众号进行查询或您也可以拨打：4008 336 886客服热线。
      </van-collapse-item>
      <van-collapse-item title="3.申请服务需要提供哪些材料？" name="3">
        需要您提供：购买人本人身份证正反面清晰照片、手机损坏部位清晰照片，手机背面清晰照片、imei清晰照片（手机盒背面或者侧面有标注/手机拨号键上输入*#06#获取串码）、购买凭证清晰照片
      </van-collapse-item>
      <van-collapse-item title="4.维修后质保？" name="4">
        维修完成后的手机屏幕享受官方质保，质保政策与品牌官方售后政策一致。屏幕质保期内因更换屏幕引起的性能故障可免费维修。     
      </van-collapse-item>
    </van-collapse>

    <div class="btn-out">
      <div class="icon-flex btn" @click="goKF">
        <headset-one class="icon2" theme="outline" fill="#fff" :strokeWidth="2"/>
        <div class="text-cont">
          <div>
            联系客服
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
import {SendEmail, HeadsetOne, PhoneTelephone} from '@icon-park/vue'


export default {
  components: {
    SendEmail,
    HeadsetOne,
    PhoneTelephone,
  },
  data () {
    return {
      list: [{}], 
      activeNames: ['1'],
    }
  },
  mounted () {
    
  },
  methods: {
    goKF () {
      window.location.href = 'https://chaten.sobot.com/chat/h5/v2/index.html?sysnum=c2c43a22afd8416b94fdc02b33ff9c00&source=1'
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-cont{
  min-height: 100.1vh;
  // box-sizing: border-box;
  // padding: 50px;
  // background-image: url(../assets/image/bg2.jpeg);
  // background-size: 100% auto;
  // background-position: 0 0;
  // background-repeat: no-repeat;
}

.btn{
  width: 300px;
  margin: 0 auto;
  align-items: center;
  justify-items: center;
  background-color: #1F955B;
  color: #fff;
  box-sizing: border-box;
  padding: 10px 50px;
  border-radius: 100px;
  line-height: 60px;
}
.btn-out{
  padding: 100px 60px;
}
</style>
